import React from 'react'
import Image from 'src/reusecore/src/elements/Image'
import UnderImage from 'src/assets/image/under_construction.png'
import FacebookIcon from 'src/assets/image/social/facebook.png'
import InstagramIcon from 'src/assets/image/social/instagram.png'
import YoutubeIcon from 'src/assets/image/social/youtube.png'

import { MainWrapper, TextWrapper, ContentWrapper } from './maintenance.style'

const MainteSec = ({ imageWrapper, title, text }) => {
  return (
    <MainWrapper>
      <ContentWrapper>
        <TextWrapper>
          <div className='heading-wrapper'>
            <h1 className='thick'>Under</h1>
            <h2 className='thick'>Construction</h2>
          </div>
          <h3 className='thin'>Development in progress</h3>
          <div className='social-media'>
            <a
              href='https://www.youtube.com/channel/UCdu5-2QQNwfZxMAkLGIcRMw'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={YoutubeIcon} className='icon' alt='Youtube' />
            </a>
            <a
              href='https://www.facebook.com/hostsoft/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={FacebookIcon}
                className='facebook-icon'
                alt='Facebook'
              />
            </a>
            <a
              href='https://www.instagram.com/hostsoft/?hl=af'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={InstagramIcon}
                className='icon-instagram'
                alt='Instagram'
              />
            </a>
          </div>
        </TextWrapper>
        <Image
          src={UnderImage}
          alt='Under construction'
          className='upperImg'
          style={{ maxWidth: '50%' }}
        ></Image>
      </ContentWrapper>
    </MainWrapper>
  )
}

export default MainteSec
