import styled from 'styled-components'
import bcgImg from 'src/assets/image/background/background_main.png'

export const MainWrapper = styled.div`
  background-image: url(${bcgImg});
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  font-family: poppins;
  color: white;
  .thick {
    font-size: 4vw;
    line-height: 40px;
  }
  .thin {
    font-weight: 100;
    font-size: 2.5vw;
  }
  a .icon {
    width: 50px;
    hieght: 50px;
  }
  a .icon-instagram {
    width: 37px;
    hieght: 37px;
  }
  a .facebook-icon {
    width: 17px;
    margin: 0px 22px;
  }

  .social-media {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 768px) {
    .thin {
      font-size: 5vw;
    }
    .thick {
      font-size: 11vw;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 414px) {
    .thin {
      font-size: 7vw;
    }
    .thick {
      font-size: 12vw;
      line-height: 20px;
    }
    a .icon {
      width: 40px;
      hieght: 40px;
    }
    a .icon-instagram {
      width: 30px;
      hieght: 30px;
    }
    a .facebook-icon {
      width: 14px;
      margin: 0px 22px;
    }
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    .upperImg {
      display: none;
    }
  }
`
