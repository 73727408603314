import React, { useState, useEffect } from 'react'
import 'src/style/cookies-style.css'
import TagManager from 'react-gtm-module'

import Link from 'gatsby-link'

const Cookies = props => {
  // Cookie banner is displayed
  const [display, setDisplay] = useState(false)
  // Display settings
  const [settings, setSettings] = useState(false)
  // Accept marketing
  const [marketing, setMarketing] = useState(true)

  useEffect(() => {
    const cookies = localStorage.getItem('cookies')
    const cookiesMarketing = localStorage.getItem('cookies_marketing')

    // Display cookie banner if not accepted
    if (!cookies) {
      setDisplay(true)
    }

    if (cookiesMarketing) {
      // Do marketing stuff

      fireMarketing()
    }
  }, [])

  const fireMarketing = () => {
    // Google Tag Manager
    // https://tagmanager.google.com/#/container/accounts/6004253762/containers/47546005/workspaces/2
    const tagManagerArgs = {
      gtmId: 'GTM-T6SJ9PR'
    }
    TagManager.initialize(tagManagerArgs)

    // Hubspot
    const hubspot_script = document.createElement('script')
    hubspot_script.setAttribute('type', 'text/javascript')
    hubspot_script.setAttribute('id', 'hs-script-loader')
    hubspot_script.setAttribute('async', '')
    hubspot_script.setAttribute('defer', '')
    hubspot_script.setAttribute('src', '//js.hs-scripts.com/19768088.js')
    document.head.appendChild(hubspot_script)
  }

  const acceptCookies = () => {
    setDisplay(false)
    localStorage.setItem('cookies', true)
    if (marketing) {
      localStorage.setItem('cookies_marketing', true)
      fireMarketing()
    }
  }

  if (!display) {
    return true
  }

  return (
    <div className='cookies-wrap'>
      <div className='cookies-wrap-one'>
        <h1 className='cookies-main-title'>This website uses cookies</h1>
        <div className='cookies-policy-wrap'></div>
        <div className='cookies-content'>
          We use cookies to ensure that we give you the best experience on our
          website to personalise content and adverts and to analyse our traffic
          using{' '}
          <a
            href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            Google Analytics
          </a>{' '}
          and{' '}
          <a
            href='https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser'
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            Hubspot
          </a>
          .
          {/* <div className='cookies-policy'>
            <Link to='/privacy-policy'>Privacy policy</Link>
          </div> */}
        </div>
      </div>
      <div className='cookies-wrap-two'>
        <div className='cookies-check-wrap'>
          <div
            onKeyDown={() => setSettings(!settings)}
            className='cookie-check'
            style={{ cursor: 'pointer' }}
            onClick={() => setSettings(!settings)}
            role='button'
            tabIndex={0}
          >
            configure
          </div>
          {settings && (
            <>
              <label className='cookie-container'>
                <div className='text'>Necessary</div>
                <input type='checkbox' checked></input>
                <span className='checkmark'></span>
              </label>
              <label className='cookie-container'>
                <div className='text'>Marketing</div>
                <input
                  type='checkbox'
                  checked={marketing}
                  onChange={e => setMarketing(e.target.checked)}
                ></input>
                <span className='checkmark'></span>
              </label>
            </>
          )}
        </div>

        <button
          type='submit'
          value='ACCEPT COOKIES'
          className='cookies-button'
          onClick={() => acceptCookies()}
        >
          ACCEPT {marketing && 'ALL'} COOKIES
        </button>
      </div>
    </div>
  )
}
export default Cookies
