import React from 'react'
import { GlobalStyle } from 'src/style/crypto.style'
import { ResetCSS } from 'src/style/style'
import SEO from 'src/components/Seo'
import Cookie from '../components/Cookies/index.js'

import MainteSec from 'src/containers/Maintenance'

const Maintenance = () => (
  <>
    <SEO title='Maintenance' />
    <ResetCSS />
    <GlobalStyle />
    <MainteSec />
    <Cookie />
  </>
)

export default Maintenance
